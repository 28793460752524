import React, { useState, useEffect } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';

import WbSunnyIcon from '@mui/icons-material/WbSunny';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import CakeIcon from '@mui/icons-material/Cake';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import NightsStayIcon from '@mui/icons-material/NightsStay';

export default function CustomizedTimeline() {
  const [currentTime, setCurrentTime] = useState(new Date());

  const weddingEndDate = new Date('2025-09-07T02:00:00');
  const greyed = 0.2;

  const timelineEvents = [
    // {
    //   startTime: new Date('2025-09-06T13:30:00'),
    //   endTime: new Date('2025-09-06T14:00:00'),
    //   title: 'Velkommen',
    //   // description: 'Vi ønsker dere velkommen til Villa Eckbo',
    //   icon: <WbSunnyIcon />,
    //   iconColor: 'timeline1',
    // },
    {
      startTime: new Date('2025-09-06T14:00:00'),
      endTime: new Date('2025-09-06T14:30:00'),
      title: 'Vielsen begynner',
      // description: 'I hagen hvis været tillater',
      icon: <FavoriteIcon />,
      iconColor: 'timeline1',
    },
    {
      startTime: new Date('2025-09-06T14:40:00'),
      endTime: new Date('2025-09-06T16:00:00'),
      title: 'Mingling og aperitiff',
      // description: 'Snacks og musserende kan nytes i hagen eller i salongene',
      icon: <BrunchDiningIcon />,
      iconColor: 'timeline2',
    },
    {
      startTime: new Date('2025-09-06T16:30:00'),
      endTime: new Date('2025-09-06T20:00:00'),
      title: 'Middag',
      // description: 'Middag og taler i hovedsalen',
      icon: <RestaurantIcon />,
      iconColor: 'timeline3',
    },
    /* {
      startTime: new Date('2025-09-06T20:00:00'),
      endTime: new Date('2025-09-06T20:30:00'),
      title: 'Middagen er over',
      description: 'Vi beveger oss ut i salongene',
      icon: <Diversity1Icon />,
      iconColor: 'timeline5',
    }, */
    {
      startTime: new Date('2025-09-06T20:30:00'),
      endTime: new Date('2025-09-06T21:30:00'),
      title: 'Kaffe og kaker',
      // description: 'Kutting av bryllupskake, baren åpnes',
      icon: <CakeIcon />,
      iconColor: 'timeline4',
    },
    {
      startTime: new Date('2025-09-06T21:30:00'),
      endTime: new Date('2025-09-07T00:30:00'),
      title: 'Brudevals, dans, og fest',
      // description: 'De nygifte valser i gang festen',
      icon: <NightlifeIcon />,
      iconColor: 'timeline5',
    },
    {
      startTime: new Date('2025-09-07T01:00:00'),
      endTime: new Date('2025-09-07T02:00:00'),
      title: 'Festen er over',
      // description: 'Brudeparet trekker seg tilbake, baren stenges',
      icon: <NightsStayIcon />,
      iconColor: 'timeline6',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60 * 1000); // Update every minute

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = (date) => {
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const makeTimelineItem = (event, index) => {
    return (
      <TimelineItem
        key={index}
        sx={{ opacity: isPastEvent(event.endTime) ? greyed : 1 }}
      >
        <TimelineOppositeContent
          sx={{ m: 'auto 0', maxWidth: '28%' }}
          align="right"
          variant="h6"
        >
          {formatTime(event.startTime)}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color={event.iconColor}>
            {React.cloneElement(event.icon, { style: { fill: 'white' } })}
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2, minWidth: 150 }}>
          <Typography variant="h6" component="span">
            {event.title}
          </Typography>
          <Typography>{event.description}</Typography>
        </TimelineContent>
      </TimelineItem>
    );
  };

  const isPastEvent = (startTime) => {
    return currentTime > startTime;
  };

  if (currentTime > weddingEndDate) {
    return (
      <h3>
        Takk for at dere var med på feiringen! Vi håper dere koste dere like mye
        som oss.
      </h3>
    );
  }

  return (
    <Timeline>
      {timelineEvents.map((event, index) => makeTimelineItem(event, index))}
    </Timeline>
  );
}