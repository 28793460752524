import React, { useEffect, useState, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Card, Box, CardContent, CardMedia, Typography } from '@mui/material';

const Wishlist = () => {
    const [wishes, setWishes] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [openBuyDialog, setOpenBuyDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedWish, setSelectedWish] = useState(null);
    const [editFormData, setEditFormData] = useState({
        name: '',
        description: '',
        link_url: '',
        price: 0,
        image_url: '',
        amount_wanted: 1,
        amount_bought: 0
    });
    const hasFetchedData = useRef(false);

    useEffect(() => {
        if (!hasFetchedData.current) {
            fetchWishes();
            checkAdminStatus();
            hasFetchedData.current = true;
        }
    }, []);

    const fetchWishes = async () => {
        try {
            const response = await fetch('/wishes');
            const data = await response.json();
            setWishes(data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
        } catch (error) {
            console.error('Error fetching wishes:', error);
        }
    };

    const truncateDescription = (description, maxLength) => {
        if (description.length > maxLength) {
            return description.slice(0, maxLength) + '...';
        }
        return description;
    };

    const checkAdminStatus = () => {
        const storedToken = localStorage.getItem('adminToken');
        if (storedToken === 'adminToken') { // Replace with your actual token validation
            setIsAdmin(true);
        }
    };

    const handleLogin = async () => {
        const username = prompt('Brukernavn:');
        const password = prompt('Passord:');
        
        if (!username || !password) return;

        try {
            const hash = hashCode(`${username}:${password}`);
            const response = await fetch(`/auth/${hash}`);
            
            if (response.ok) {
                localStorage.setItem('adminToken', 'adminToken');
                setIsAdmin(true);
            } else {
                alert('Invalid credentials');
            }
        } catch (error) {
            console.error('Authentication error:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        setIsAdmin(false);
    };

    const hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = (hash << 5) - hash + str.charCodeAt(i);
            hash |= 0;
        }
        return hash;
    };

    const handleBuyDialogOpen = (wish) => {
        setSelectedWish(wish);
        setOpenBuyDialog(true);
    };

    const handleBuyDialogClose = () => {
        setOpenBuyDialog(false);
        setSelectedWish(null);
    };

    const handleEditDialogOpen = (wish) => {
        setSelectedWish(wish);
        setEditFormData(wish || {
            name: '',
            description: '',
            link_url: '',
            price: 0,
            image_url: '',
            amount_wanted: 1,
            amount_bought: 0
        });
        setOpenEditDialog(true);
    };

    const handleEditDialogClose = () => {
        setOpenEditDialog(false);
        setSelectedWish(null);
        setEditFormData({
            name: '',
            description: '',
            link_url: '',
            price: 0,
            image_url: '',
            amount_wanted: 1,
            amount_bought: 0
        });
    };

    const handleBuyConfirm = async () => {
        if (selectedWish.amount_bought >= selectedWish.amount_wanted) {
            alert('Denne gaven er allerede gitt.');
        } else {
            const updatedWish = {
                ...selectedWish,
                amount_bought: selectedWish.amount_bought + 1,
                bought_out: selectedWish.amount_bought + 1 >= selectedWish.amount_wanted
            };

            try {
                const response = await fetch(`/wishes/${selectedWish.id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedWish)
                });
                if (response.ok) {
                    fetchWishes();
                }
            } catch (error) {
                console.error('Error updating wish:', error);
            }
        }
        handleBuyDialogClose();
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const isNewWish = !selectedWish?.id;
        const updatedWish = {
            ...editFormData,
            bought_out: editFormData.amount_bought >= editFormData.amount_wanted
        };

        try {
            const url = isNewWish ? '/wishes' : `/wishes/${selectedWish.id}`;
            const method = isNewWish ? 'POST' : 'PUT';
            
            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedWish)
            });

            if (response.ok) {
                fetchWishes();
                handleEditDialogClose();
            }
        } catch (error) {
            console.error('Error saving wish:', error);
        }
    };

    const handleDelete = async (wish) => {
        if (window.confirm(`Er du sikker på at du vil slette "${wish.name}"?`)) {
            try {
                const response = await fetch(`/wishes/${wish.id}`, {
                    method: 'DELETE'
                });
                if (response.ok) {
                    fetchWishes();
                }
            } catch (error) {
                console.error('Error deleting wish:', error);
            }
        }
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 40 }}>

            <div style={{ display: 'flex', flexWrap: 'wrap', flexFlow: 'row wrap', justifyContent: 'center', gap: 40 }}>
                {wishes.map((wish) => (
                    <Card
                        key={wish.id}
                        style={{
                            margin: '10px',
                            width: '300px',
                            height: 'fit-content',
                            boxShadow: 'none',
                            overflow: 'visible',
                            opacity: wish.bought_out ? 0.6 : 1,
                            filter: wish.bought_out ? 'grayscale(100%)' : 'none',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <CardMedia
                            component="img"
                            alt=""
                            height="200"
                            image={wish.image_url}
                            style={{
                                objectFit: 'cover',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                                borderRadius: '10px'
                            }}
                        />
                        <CardContent sx={{ padding: 0, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" component="a" href={wish.link_url} sx={{ color: 'inherit', display: 'block', flexGrow: 1 }}>
                                {wish.name}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1 }}>
                                <Typography variant="body1" color="textSecondary" component="p">
                                    kr. {wish.price},-
                                </Typography>
                                <Typography variant="body1" color="textSecondary" component="p">
                                    {wish.amount_bought}/{wish.amount_wanted} kjøpt
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                onClick={() => handleBuyDialogOpen(wish)}
                                target="_blank"
                                style={{ marginTop: '10px', width: '100%' }}
                            >
                                Registrer kjøp
                            </Button>
                            <Typography variant="body1" color="textSecondary" component="p" sx={{ flexGrow: 0, marginTop: '10px' }}>
                                {truncateDescription(wish.description, 100)}
                            </Typography>
                            {isAdmin && (
                                <div className="flex gap-2">
                                    <Button onClick={() => handleEditDialogOpen(wish)}>
                                        Edit
                                    </Button>
                                    <Button 
                                        onClick={() => handleDelete(wish)}
                                        variant="destructive"
                                    >
                                        Delete
                                    </Button>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                ))}

                <Dialog
                    open={openBuyDialog} 
                    onClose={handleBuyDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Bekreft kjøp"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Er du sikker på at du vil registrere kjøpet av {selectedWish?.name}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleBuyDialogClose}>Avbryt</Button>
                    <Button onClick={handleBuyConfirm} autoFocus>Bekreft</Button>
                    </DialogActions>
                </Dialog>

                {/* Edit/Add Wish Dialog */}
                <Dialog open={openEditDialog} onClose={handleEditDialogClose}>
                    <form onSubmit={handleEditSubmit}>
                        <DialogTitle>
                            {selectedWish ? 'Rediger ønske' : 'Legg til nytt ønske'}
                        </DialogTitle>
                        <DialogContent>
                            <div className="space-y-8 mt-8">
                                <TextField
                                    label="Navn"
                                    value={editFormData.name}
                                    onChange={(e) => setEditFormData({...editFormData, name: e.target.value})}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    label="Beskrivelse"
                                    value={editFormData.description}
                                    onChange={(e) => setEditFormData({...editFormData, description: e.target.value})}
                                    fullWidth
                                    multiline
                                />
                                <TextField
                                    label="Link URL"
                                    value={editFormData.link_url}
                                    onChange={(e) => setEditFormData({...editFormData, link_url: e.target.value})}
                                    fullWidth
                                />
                                <TextField
                                    label="Bilde URL"
                                    value={editFormData.image_url}
                                    onChange={(e) => setEditFormData({...editFormData, image_url: e.target.value})}
                                    fullWidth
                                />
                                <TextField
                                    label="Pris"
                                    type="number"
                                    value={editFormData.price}
                                    onChange={(e) => setEditFormData({...editFormData, price: parseFloat(e.target.value)})}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    label="Ønsket antall"
                                    type="number"
                                    value={editFormData.amount_wanted}
                                    onChange={(e) => setEditFormData({...editFormData, amount_wanted: parseInt(e.target.value)})}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    label="Kjøpt antall"
                                    type="number"
                                    value={editFormData.amount_bought}
                                    onChange={(e) => setEditFormData({...editFormData, amount_bought: parseInt(e.target.value)})}
                                    fullWidth
                                    required
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleEditDialogClose}>Avbryt</Button>
                            <Button type="submit">Lagre</Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>

            <div>
                <Button 
                    onClick={isAdmin ? handleLogout : handleLogin}
                    className="mr-2"
                >
                    {isAdmin ? 'Logg ut' : 'Admin Login'}
                </Button>
                {isAdmin && (
                    <Button onClick={() => handleEditDialogOpen(null)}>
                        Legg til +
                    </Button>
                )}
            </div>

        </div>
        
    );
};


export default Wishlist;