import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Root from './components/Root';
import { Box } from '@mui/material';



const theme = createTheme({
  palette: {
    primary: {
      main: '#748cab', // Mauve
      light: '#F0E6FF', // Lavender
      dark: '#191970', // Dark Lavender
    },
    secondary: {
      main: '#FFD700', // Gold
      light: '#FFFF8D', // Light Gold
      dark: '#C9A600', // Dark Gold
    },
    background: {
      default: '#FFFFFF', // White
      paper: '#F5F5F5', // Light Gray
    },
    text: {
      primary: '#333333', // Dark Gray
      secondary: '#666666', // Medium Gray
    },
    error: {
      main: '#FF4136', // Red
    },
    warning: {
      main: '#FF851B', // Orange
    },
    info: {
      main: '#4FC3F7', // Blue
    },
    success: {
      main: '#2ECC40', // Green
    },
    timeline1: {
      main: '#ff0038', 
    },
    timeline2: {
      main: '#c20960', 
    },
    timeline3: {
      main: '#7c148e', 
    },
    timeline4: {
      main: '#681886', 
    },
    timeline5: {
      main: '#511d7c', 
    },
    timeline6: {
      main: '#2f246e', 
    },
    timeline7: {
      main: '#4B0082', 
    },
    timeline8: {
      main: '#191970', 
    },
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>
        <Root />
      </Box>
    </ThemeProvider>
  );
}

export default App;
