import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box } from '@mui/material';

export default function Spørsmål() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, margin: 0, maxWidth: 600 }}>
            <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Hva har man på seg?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Kleskoden er smoking/mørk dress.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Kan jeg parkere der?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Det går an å parkere over natten på veien utenfor lokalet. Lokalet er også i nærheten av Ris og Slemdal T-banestasjoner, så det er mulig å ta T-banen.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Jeg vil gjerne holde tale!</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Hvis du ønsker å holde tale i løpet av bryllupet, ta kontakt med toastmaster Camilla på <a href='mailto:camilla_klafstad@hotmail.com'>camilla_klafstad@hotmail.com</a>,
                    så kan hun hjelpe deg.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Er det åpent for å ha med barn? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Vi ønsker å ha et barnefritt bryllup, og håper på forståelse for det.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{width: '100%'}}>
                <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                <Typography>Fikk du ikke svar på det du lurte på?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Vennligst ta kontakt med <a href='mailto:torjus.klafstad@gmail.com'>Torjus</a> eller <a href='mailto:vicand96@gmail.com'>Victoria</a> hvis dere har noen spørsmål.
                    
                </Typography>
                </AccordionDetails>
            </Accordion>
        
        </Box>
    );
}